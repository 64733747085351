import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../Assets/camjets_logo-white.png";

const Footer = () =>
{
  return (
    <footer className='footer'>
      <div className='linkSection'>
        <div style={styles.linksColumn}>
          <Link to="/" style={styles.link}>Home</Link>
          <Link to="/aboutus" style={styles.link}>About Us</Link>
          <Link to="/whyprivatejet" style={styles.link}>Why Private Jets</Link>
        </div>
        <div style={styles.logoSection}>
          <img
            src={logo}
            alt="Logo"
            style={styles.logo}
          />
        </div>
        <div style={styles.linksColumn}>
          <Link to="/fractional" style={styles.link}>Program</Link>
          <Link to="/fleet" style={styles.link}>Fleet</Link>
          <Link to="/contact" style={styles.link}>Contact Us</Link>
        </div>
      </div>

      <div style={styles.divider}></div>

      <div style={styles.copyrightSection}>
        <p style={styles.copyrightText}>
          © {new Date().getFullYear()} CamJets. All Rights Reserved.
        </p>

       
      </div>
    </footer>
  );
};

const styles = {
  linksColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '10px 0',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    margin: '0 15px',
    fontSize: '16px',
  },
  logoSection: {
    textAlign: 'center',
    margin: '10px 0',
  },
  logo: {
    height: '50px',
    width: 'auto',
  },
  divider: {
    borderTop: '0.8px solid #fff',
    margin: '20px 0',
  },
  copyrightSection: {
    textAlign: 'center',
    padding: '10px 0',
  },
  copyrightText: {
    margin: 0,
    fontSize: '14px',
  },
  createdByText: {
    margin: 0,
    fontSize: '14px',
    color: '#fff',
    cursor: 'pointer',
  },
};

export default Footer;
