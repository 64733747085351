import React, { useEffect } from "react";
import "../styles/Static.css";
import happyimage from "../Assets/happyimg.png";
import businessimage from "../Assets/businessimg.svg";
import aeroplaneimage from "../Assets/aeroplaneimg.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Static()
{
  useEffect(() =>
  {
    AOS.init();
  }, [])
  return (
    <div className="static">
      <div className="takeoffpara" data-aos="fade-up">
        <p> CamJets,</p>
        <p>Cambodia’s 1st Private Jet Company Is Now</p>
        <p> Ready For Take-Off!</p>
      </div>
      <div className="firstbox" data-aos="fade-right">
        <div className="firstboxleft">
          It truly is an exciting time in one of the fastest-growing economies
          in the world. A young, proud and optimistic population has been the
          driving force behind the re-emergence of an amazing country. With a
          stable government committed to free markets, due process and the rule
          of law, Cambodia has become one of the most dynamic countries in
          Southeast Asia.
        </div>
        <img className="happyimg" src={happyimage} alt="" />
      </div>

      <div className="secondbox" data-aos="fade-left">
        <img src={businessimage} alt="" />
        <div className="secondboxright">
          The International Monetary Fund expects Cambodia to become Southeast
          Asia’s fastest-growing economy by 2025. For the country to continue to
          achieve such growth, access to global markets becomes an imperative.
          With limited commercial air connectivity, business and political
          leadership use Private Jets on charter to make the best of their time.
        </div>
      </div>

      <div className="thirdbox" data-aos="fade-right">
        <div className="thirdboxleft">
          Paying exorbitantly high charter rates due to the fact that there are
          no business jets operating from Cambodia.
          <br /> All this is now set to
          change with the launch of CamJets from Royal Group.
        </div>
        <img className="happyimg" src={aeroplaneimage} alt="" />
      </div>
    </div>
  );
}


