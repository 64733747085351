import React, { useEffect } from "react";
import "../styles/Whoweare.css";
import whoweareimg from "../Assets/whoweareimg.png";
import symbol from "../Assets/Symbol.png";
import bgimg from "../Assets/bgimg.png";
import Aos from "aos";
import { Link } from "react-router-dom";
export default function Whoweare()
{
  useEffect(() =>
  {
    Aos.init();
  }, [])
  return (
    <div className="whowearemaindiv">
      <div className="whoweareleftdiv">
        <div className="whowearefirstdiv">
          <div className="content1">
            <img className="symbolimg" src={symbol} alt="" />
            <div className="headingdiv">
              <p className="headingg1">Luxury flight</p>
              <p className="headingg2">Elevated elegance in every journey.</p>
            </div>
          </div>

          <div className="content1">
            <img className="symbolimg" src={symbol} alt="" />
            <div className="headingdiv">
              <p className="headingg1">Best Services</p>
              <p className="headingg2">Tailored excellence, every time.</p>
            </div>
          </div>

          <div className="content1">
            <img className="symbolimg" src={symbol} alt="" />
            <div className="headingdiv">
              <p className="headingg1">Certified Pilot</p>
              <p className="headingg2">Expert pilots, unmatched safety.</p>
            </div>
          </div>
        </div>
        <div className="whowearesecondiv">
          <img className="whoweareimg" src={whoweareimg} alt="" />
        </div>
      </div>

      <div className="whowearerightdiv">
        <p className="aboutustext">About us</p>
        <p className="who">Who <span className="yellow">We Are</span></p>
        <p className="para1">
          CamJets is another initiative by the Royal Group, recognised as
          Cambodia’s most dynamic and diversified business conglomerate.
        </p>
        <p className="para2">
          With interests in a wide range of industries including Banking &
          Finance, Insurance, Investment, Telecommunications, Power Energy,
          Transport, Media & Entertainment, Hotels & Resorts, Education,
          Property Development, Trading, and Special Economic Zones, the Royal
          Group is recognised as a conglomerate that identifies challenges faced
          by the country and creates successful businesses that are good for the
          economy.
        </p>
        <br />
        <Link to={'/aboutus'} style={{ textDecoration: "none" }}>
          <button className="viewmorebtn">
            view more</button>
        </Link>
      </div>
    </div>
  );
}
