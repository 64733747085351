

import React, { useState, useEffect, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FaBars, FaTimes } from 'react-icons/fa';
import "../styles/Navbar.css";
import logo from "../Assets/camjets_logo-white.png";
import { useLocation } from 'react-router';
export const preloadImage = (src) =>
{
  return new Promise((resolve, reject) =>
  {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

const Navbar = () =>
{
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [underSec, setUnderSet] = useState("home");
  const menuRef = useRef(null);
  const location = useLocation()
  const toggleMenu = () =>
  {
    setIsOpen(!isOpen);
  };

  const handleScroll = () =>
  {
    if (window.scrollY > 50)
    {
      setScroll(true);
    } else
    {
      setScroll(false);
    }
  };
  useEffect(() =>
  {
    preloadImage(logo).then(() => { })

  }, [])
  const handleClickOutside = (event) =>
  {
    if (menuRef.current && !menuRef.current.contains(event.target))
    {
      setIsOpen(false);
    }
  };

  useEffect(() =>
  {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () =>
    {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() =>
  {

    setUnderSet(localStorage.getItem('nav'))
  }, [location.pathname])
  useEffect(() =>
  {

    localStorage.getItem("nav", 'home')
    setUnderSet(localStorage.getItem('nav'))

  }, [])

  return (
    <nav className={scroll ? 'scrolled' : ''}>
      <div className="respo-nav">
        <div className={`hamburger ${ scroll ? 'scrolled' : '' }`} onClick={toggleMenu}>
          {isOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
        </div>
        <Link to={"/"}>
          <div className="righty">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Link>
      </div>
      <div className={`mainnvbr fonts ${ isOpen ? 'open' : '' }`} ref={menuRef}>
        <Link style={{ marginRight: "1vh" }} onClick={() =>
        {
          localStorage.setItem("nav", "home")
          toggleMenu()
        }} to="/">
          {underSec === "home" ? <p style={{ textDecoration: "underline" }}>Home</p> : <p className='border'>Home</p>}
        </Link>
        <div className="dropdown">
          <Link onClick={() => localStorage.setItem("nav", "aboutus")
          } to="/aboutus">
            {underSec === "aboutus" ? <p style={{ textDecoration: "underline", left: "-10px" }}>About Us</p> : <p className='border'>About Us</p>}
          </Link>
          <div className="dropdown-content">
            <Link onClick={() =>
            {
              localStorage.setItem("nav", "aboutus")
              toggleMenu()
            }} className='under' to="/aboutus#chairman">Message from the Chairman</Link>
            {/* <Link className='under' to="/">Membership</Link> */}
            <Link className='under' to="/aboutus#vision" onClick={() => toggleMenu()}>Vision & Mission</Link>
            <Link className='under' to="/aboutus#team" onClick={() => toggleMenu()}>Team</Link>

          </div>
        </div>

        <Link onClick={() =>
        {
          localStorage.setItem("nav", "private")
          toggleMenu()
        }} to="/whyprivatejet">
          {underSec === "private" ? <p style={{ textDecoration: "underline" }}>Why Private Jets</p> : <p className='border'>Why Private Jets</p>}
        </Link>
        <Link className="click-home" to="/">
          <div className="center-new">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Link>
        <div className="dropdown">
          <Link onClick={() => localStorage.setItem("nav", "prog")


          } to="/fractional">
            {underSec === "prog" ? <p style={{ textDecoration: "underline", left: "-10px" }}>Program</p> : <p className='border'>Program</p>}
          </Link>
          <div className="dropdown-content">
            <Link onClick={() =>
            {
              localStorage.setItem("nav", "prog")
              toggleMenu()
            }} className='under' to="/fractional">Fractional Membership</Link>
            {/* <Link className='under' to="/">Membership</Link> */}
            {/* <Link onClick={() =>
            {
              localStorage.setItem("nav","prog")
              toggleMenu()
            }} className='under' to="/fractional">Charters</Link> */}
          </div>
        </div>
        <div className="dropdown">
          <Link onClick={() =>
          {
            localStorage.setItem("nav", "fleet")
            toggleMenu()
          }


          } to="/fleet">
            {underSec === "fleet" ? <p style={{ textDecoration: "underline" }}>Fleet</p> : <p className='border'>Fleet</p>}
          </Link>
        </div>

        <Link onClick={() =>
        {
          localStorage.setItem("nav", "contactus")
          toggleMenu()
        }} to="/contact">
          {underSec === "contactus" ? <p style={{ textDecoration: "underline" }}>Contact Us</p> : <p className='border'>Contact Us</p>}
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;

