

import React, { useEffect } from "react";
import "../styles/about-us.css";
import happyimage from "../Assets/happyimg.png";
import happyimage2 from "../Assets/3rd.jpeg";
import businessimage from "../Assets/businessimg.svg";
import aeroplaneimage from "../Assets/aeroplaneimg.png";
import topimg from "../Assets/Challenger 605 Exterior Above Desert (1) (1).jpg"
import team from "../Assets/team.jpg"
import Vision from "../Assets/vision.jpg"
import about from "../Assets/about.jpeg"
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Aos from "aos";

const Aboutuss = () =>
{
  useEffect(() =>
  {
    Aos.init();
  }, [])
  return (
    <>
      <div className="about-main">
        <div className="fractional-sec2">
          <img src={topimg} alt="" className="img-fix" />
          <div className="overlay-fraction">
            <div className="textABD">
              <h2> <span style={{ color: "#FFC900" }}>CamJets</span> </h2>

              <p>
                Launching Cambodia’s 1st Private Jet Fractional Membership Program!
              </p>
            </div>
          </div>
        </div>
        <div className="second-div">
          <div className="firstbox-2" data-aos="fade-right">
            <img className="happyimg-2" src={about} alt="" />
            <div className="firstboxleft-2">
              <p >
                CamJets is  an initiative by the Royal Group, recognised as Cambodia’s most dynamic and diversified business conglomerate. <br />
                With interests in a wide range of industries including Banking & Finance, Insurance, Investment, Telecommunications, Power Energy, Transport, Media & Entertainment, Hotels & Resorts, Education, Property Development, Trading, and Special Economic Zones, the Royal Group is recognised as a conglomerate that identifies challenges faced by the country and creates successful businesses that are good for the economy.</p>
            </div>


          </div>
        </div>
        <div id="chairman" className="div">
          <div className="third-div" data-aos="fade-left">

            <div className="firstbox-3">

              <div className="firstboxleft-3">
                <h1 className="desktopview">Message from the Chairman</h1>
                <br />
                <p>
                  <span className="comma">"</span>  CamJets is a venture set up by the Royal Group that has now
                  identified a pressing need for Cambodia’s business leaders to
                  effectively manage their time when they travel across the world
                  on business. <br /> <br /> With limited air connectivity, infrequent and
                  inconvenient schedules and long waits at airports, valuable time
                  is wasted on travel. <br /> <br /> <strong>Time we can ill-afford to waste.</strong>   <br />  <br />World
                  leaders use Private Jets to manage their time more effectively. <br />
                  But while there is significant demand for Charters there is no
                  Private Jet service that operates from Cambodia. <br /><br /> To this end we
                  have now decided to launch this business to help those who value
                  time more that money to make the best use of this service and
                  their time. <br /><br /> <strong>CamJets</strong> is a result of over a year of diligent
                  market study, identifying the right partners and putting
                  together a team of aviation veterans that has now come to
                  fruition. <br /><br /> We at Royal Group are delighted to continue bringing
                  international investments to Cambodia. We look forward to your
                  support so together we can take the nation to the next
                  stage. <span className="comma">"</span>  <br /> <br />
                  <div className="chairman">Neak Oknha Kith Meng  <br /> Chairman & CEO of The Royal Group </div>
                </p>
              </div>

              <img className="happyimg-3" src={happyimage2} alt="" />
              <h1 className="mobileview">Message from the Chairman</h1>

            </div>
          </div>
        </div>
        <div id="vision" className="div"></div>
        <div className="forth" data-aos="fade-right">
          <div className="v-m"><p>Vision & Mission</p></div>
          <div className="forth-div">

            <img className="happyimg-4" src={Vision} alt="" />
            <div className="secondboxright-4">
              <h1 className="sub-head">Vision:</h1>
              <p>
                To be Cambodia’s Premier Business jet company, for those who value
                time more than money!
              </p>
              <br />
              <br />
              <h1 className="sub-head">Mission:</h1>
              <p>
                To ensure that successful leaders in Cambodia, make the best
                utilisation of their time as they travel across the world by
                delivering world class, safe, reliable and personalised travel in
                business jets based out of Cambodia.
              </p>
            </div>
          </div>
        </div>
        <div id="team" className="div">
          <div className="fifth" data-aos="fade-up">
            <div className="team"><p>Team</p></div>
            <div className="fifth-box">
              <div className="thirdboxleft-5">
                <p>
                  CamJets is being set up by veterans  from  the aviation sector who
                  have a wealth of experience in managing  both large & small
                  aviation businesses  in the commercial as well as the business
                  aviation space. The management team and its core members come
                  with  decades of experience spanning management, engineering and
                  operations, across countries. <br /> Our objective is to provide
                  successful business leaders with best-in-class service, both
                  inflight as well as on ground backed by world class safety
                  standards.  <br /><br /> Service that  will be on par with international standards,
                  following rigorous safety procedures and a highly personalised
                  inflight experience. <br /><br /> The company plans to commence operations by
                  the end of the year.
                </p>
              </div>
              <img className="happyimg" src={team} alt="fg" />
            </div>
          </div>
        </div>
        <div className="last-sec bgi">
          <h2>To know more about how we can help you save valuable time and money:</h2>
          <div className="button">
            <Link to={'/contact'} class="contact-button">CONTACT US</Link>
          </div>
        </div>
      </div>

    </>
  );
};

export default Aboutuss;
