import Home from "./components/Home";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom'
import Navbar from "./components/Navbar";
import Whyprivatejet from "./components/Whyprivatejet"
// import Aboutus from "./components/Aboutus";
import Fleet from "./components/Fleet";
import Aboutuss from "./components/Aboutuss"
import logo from "./Assets/images.png";

import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import FractionalMembership from "./components/FractionalMembership";
import { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";


function App()
{

  return (
    <div >
      <Router>
        <ScrollToTop />
        <Navbar />
        <Link to={"/contact"} className="sticky-buttonz">
          CONTACT US
        </Link>
        <div className="sticky-logo">
          <img style={{   borderRadius:"50%", width:"20%", height:"20%"

}} src={logo} alt="" />
        </div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/whyprivatejet" element={<Whyprivatejet />}></Route>
          <Route path="/aboutus" element={<Aboutuss />}></Route>

          <Route path="/whyprivatejet" element={<Whyprivatejet />}></Route>
          <Route path="/fleet" element={<Fleet />}></Route>

          <Route path="/contact" element={<ContactUs />} />
          <Route path="/fractional" element={<FractionalMembership />} />

        </Routes>
        <Footer />
      </Router>
    </div >
  );
}

export default App;
