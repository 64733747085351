import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Static from './Static'
import Whoweare from './Whoweare'
import HeroSection from './hero'
import StorageTimeline from './StorageTimeline'
import ContactUs from './ContactUs'
import FractionalMembership from './FractionalMembership'
import Vedioo from './Vedioo'
export default function Home()
{
        return (
                <div>
                       
                        <Navbar />
                        <HeroSection />
                        <Static />
                        <Whoweare />
                        <StorageTimeline />

                </div>
        )

}